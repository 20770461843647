import React, {useState} from 'react'
import {Components} from 'eyecons-components'
import * as Requests from 'eyecons-requests'
import {Container} from "src/components";

type ChannelVideosProps = {
    channel: Requests.Channel
}

export function ChannelVideos({channel}: ChannelVideosProps) {
    const [queryParams, setQueryParams] = useState<any>({
        channel_id: channel.id,
        sort: ['starred desc', 'created_at desc'],
        type: ['vod', 'event'],
        tags: true,
        children: true,
        paginate: 12,
        page: 1,
    })

    const videos = Requests.useVideos(queryParams, {public: true, lazyloading: true})
    const editSelect = (value: string, paramValue: string) => {
        let params = {...queryParams, page: 1}
        value ? params[paramValue] = value : delete params[paramValue]
        setQueryParams(params)
    }

    return <Container className={'pt-8 grid grid-cols-1 gap-4 sm:gap-8 lg:grid-cols-3 xl:grid-cols-4'}>
        <div className={'max-w-lg w-full flex flex-col gap-8 mx-auto'}>
            <div>

                <Components.Playlists.Dropdown
                    label={'Playlists'}
                    labelVariant={'title'}
                    queryParams={{channel_id: channel.id, hidden: 0}}
                    value={queryParams.category}
                    onChange={(value: string) => editSelect(value, 'category')}
                    isPublic
                    categories
                />
            </div>
            {channel.teams_count > 1 && channel.type.name === 'club' &&
                <div>
                    <h3 className={'storybook-title-playlist'}>Teams</h3>
                    <Components.Teams.Dropdown
                        queryParams={{club_id: channel.id}}
                        value={queryParams.team}
                        onChange={(value: string) => editSelect(value, 'team')}
                    />
                </div>
            }

            <Components.Ads.Ad type={'rectangle-halfpage'}/>
        </div>


        <div className={'lg:col-span-2 xl:col-span-3'}>
            <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full gap-4'}>
                {
                    videos.data.map((video, index) => {
                        return <Components.Videos.Card key={index} video={video}/>
                    })
                }
            </div>
            <Components.Lists.Lazyload
                loading={videos.isLoading}
                load={videos?.meta?.current_page !== videos?.meta?.last_page}
                onEnter={() => setQueryParams({...queryParams, page: (videos?.meta?.current_page || 1) + 1})}
            />
        </div>
    </Container>
}

